<template>
    <div class="providers">
        <p class="providers__title">
          <Translate translate="providerTitle" />
        </p>
        <div class="providers__list">
            <div
                v-for="provider in data"
                :key="provider.id"
                class="providers__item"
                @click="selectProvider(provider)">
                <img
                    :src="provider.logo"
                    :alt="provider.name"
                    class="providers__item-logo" />
                <p>
                    <ArrowIcon />
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import ArrowIcon from '@/components/icons/ArrowIcon';

export default {
    name: 'ProviderModal',
    components: {
        ArrowIcon,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    methods: {
        selectProvider(provider) {
            this.$emit('selected', provider);
        },
    }
};
</script>

<style lang="scss" scoped>

.providers {

    background: #fff;
    width: 100%;
    height:100%;
    position: absolute;
    top: 55px;
    padding: 12px;
    box-sizing: border-box;
    z-index: 9;
    &__list {
        padding-top: 12px;
    }
    &__title {
        padding: 20px 0;
        font-size: 15px;
        font-weight: 500;
        color: #7A808B;
    }
    &__item {
        display: grid;
        grid-template-columns: 1fr 24px;
        padding: 8px;
        height: 54px;
        box-sizing: border-box;
        align-items: center;
        cursor: pointer;
        &-logo {
            width: auto;
            height: 24px;
            object-fit: contain;
        }
        svg {
            transform: rotate(-90deg);
        }
        p {
            text-align: right
        }
    }
};
</style>
