export default {
  translations: (state) => state.translations,
  getLocale: (state) => state.config.lang,
  sources: (state) => state.sources,
  getSettings: (state) => state.settings,
  getSelectedSource: (state) => state.selectedSource,
  getConnectedSources: (state) => state.connectedSources,
  getSuggestedSources: (state) => state.suggestedSources,
  getAvailableSources: (state) => {
    let availableSources = [];
    // Check if available data sources are empty and set all sources
    if (state.config.search_available_datasources === null) {
      return {
        sources: state.sources?.sources,
      };
    }
    // Find available data sources that have been set in the configuration
    state.config.search_available_datasources.forEach(
      (sourceId) => {
        const available = state.sources?.sources.find((source) => source.ID === sourceId);
        if (!available) {
          return;
        }
        availableSources = [...availableSources, available];
      },
    );
    return {
      sources: availableSources,
    };
  },
  getConnectLogoPosition: (state) => state.connectLogoPosition,
  getSearchInputFocused: (state) => state.searchInputFocused,
  getCloseModalEnabled: (state) => state.closeModalEnabled,
  getFacebookModal: (state) => state.facebookModal,
  getTermsOfUseModal: (state) => state.termsOfUseModal,
  getTermsOfUsePartner: (state) => state.termsOfUsePartner,
  getLoginCredentials: (state) => state.loginCredentials,
  getError: (state) => state.error,
  getCountryModal: (state) => state.countryModal,
  getFilterCountryModal: (state) => state.filterCountryModal,
  getCountrySelected: (state) => state.countrySelected,
  getCodeModal: (state) => state.codeModal,
  getApprovalModal: (state) => state.approvalModal,
  getApprovalWithCodeModal: (state) => state.approvalWithCodeModal,
  getFetchUUID: (state) => state.fetchUUID,
  getFetchStatus: (state) => state.fetchStatus,
  getConfig: (state) => state.config,
  getAccountId: (state) => state.accountId,
  getRefreshed: (state) => state.refreshed,
  getCountries: (state) => state.dialCodes,
  getClientName: (state) => state.clientName,
  getAppUUID: (state) => state.config.uuid,
  getOnLoginTab: (state) => state.onLoginTab,
  getFileUpload: (state) => state.document,
  getLoadMoreModal: (state) => state.loadMoreModal,
  getTaxModal: (state) => state.taxModal,
  getDateModal: (state) => state.dateModal,
  getProvider: (state) => state.selectedProvider,
};
