<template>
  <div class="terms">
    <div class="terms__section">
      <div class="terms__section-icon">
        <ProtectedIcon />
      </div>
      <h5><Translate :translate="'secureAccess'" /></h5>
    </div>

    <p class="terms__privacy-policy" :dir="textDirection">
      <Translate :translate="'termsPrivacyPolicyDescriptionLogin_1'" />
      <Translate :translate="'termsPrivacyPolicyDescriptionLogin_2'" />
      <Translate :translate="'termsPrivacyPolicyDescriptionLogin_3'" />
      <a href="#" @click.prevent="legalInfoModal('privacy')" class="page__link">
        <Translate :translate="'termsPrivacyPolicy'" />
      </a>
      <span v-if="isRtl"> Rollee </span>
    </p>
    <p class="terms__additional-policy" v-if="includesAdditionalTerms" :dir="textDirection">
      <Translate :translate="'termsAdditionalPrivacyPolicyDescription'" />
      {{clientTitle}}'s
      <a href="#" @click.prevent="legalInfoModal(clientName)" class="page__link">
        <Translate :translate="'termsFooterToggleAcceptLink'" />
      </a>
    </p>
    <p class="terms__protect-data" @click="loadMore()"><Translate :translate="'learnMore'" /> ></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProtectedIcon from '@/components/icons/ProtectedIcon';
import Localization from '@/mixins/localization';

export default {
  name: 'TermsOfUse',
  components: {
    ProtectedIcon,
  },
  mixins: [Localization],
  data() {
    return {
      termsUrl: this.$store.state.translations.termsOfUseUrl,
      privacyUrl: this.$store.state.translations.privacyPolicyUrl,
      additionalTermsFor: this.$store.state.settings.additionalTerms,
    };
  },
  computed: {
    clientTitle() {
      return this.$store.state.config.partner_title;
    },
    clientName() {
      return this.$store.state.config.partner_name;
    },
    includesAdditionalTerms() {
      return this.additionalTermsFor.find(
        (customer) => customer === this.clientName,
      );
    },
    ...mapGetters(['getConfig', 'getSelectedSource']),
  },
  methods: {
    legalInfoModal(legalType) {
      switch (legalType) {

        case 'moove':
        case 'rollee':
          this.$store.commit('SET_TYPE_OF_TERMS', legalType);
          break;
        case 'privacy':
          this.$store.commit('SET_TYPE_OF_TERMS', legalType);
          window?.analytics.track('privacy_policy_terms');
          break;
        default:
          this.$store.commit('SET_TYPE_OF_TERMS', undefined);
          window?.analytics.track('terms_of_use_terms');
      }
      this.$store.commit('SET_LEGAL_INFO_MODAL', true);
    },
    loadMore() {
      this.$store.dispatch('setLoadMoreModal', true);
    },
  },
};
</script>

<style scoped lang="scss">
.terms {

  margin-top: 14px;

  &__section {
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    &-icon{
      width: 14px;
      height: 14px;
      display: flex;
      margin-right: 12px;
      & svg{
        width: 100%;
        height: 100%;
      }
    }

    h5 {
      font-size: 12px;
      color: $dark-grey;
      transform: translateY(2px);
      font-weight: 500;
    }
  }
  &__privacy-policy {
    font-size: 9px;
    line-height: 18.5px;
    text-align: left;
    color: $dark-grey;
    font-weight: 400;
  }

  &__additional-policy {
    margin-top: 9px;
    font-size: 11px;
    line-height: 17.5px;
    text-align: left;
    color: $dark-grey;
    font-weight: 400;
  }

  &__protect-data {
    font-size: 9px;
    font-weight: 600;
    color: $black;
    cursor: pointer;
  }
}

</style>
