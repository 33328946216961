<template>
  <div class="page page--success">
    <Header :hasClose="true" />

    <div class="success__wrapper-platforms" v-if="getConfig.success_available_datasources">
      <div
        class="success__section"
        :class="{
          'success__section--merged': getSelectedSource?.merged_logo,
        }">
        <DataSourceAvatar :source="getSelectedSource" :withShadow="true" />
        <h2 class="success__section-title">
          <Translate :translate="'completed'" />
        </h2>
        <p class="success__section-text" v-if="getConfig.success_show_description">
          <span v-if="getConfig.success_description">{{getConfig.success_description}}</span>
          <Translate v-else :translate="'accountAddedSuccessfully'" />
        </p>
      </div>
      <div class="page__list">
          <ul v-if="filteredSource.length">
            <li
              v-for="(source) in filteredSource"
              :key="source.name"
              @click="connect(source)"
              :class=" {
                'page__list--merged': source?.merged_name,
              }"
            >
              <div class="page__list-icon">
                <img
                  v-if="source?.merged_logo"
                  :src="source.merged_logo"
                  :alt="source.merged_name" />
                <img
                  v-if="!source?.merged_logo && getAvatar(source)"
                  :src="getAvatar(source)"
                  :alt="source.name" />
                <div
                  v-if="!source?.merged_logo && !!getAvatar(source)"
                  class="page__list-default" />
              </div>
              <div class="page__list-name">
                <h2 v-html="source?.merged_name || source.name" />
              </div>
              <div class="page__list-action">
                <CarrotRightIcon />
              </div>
            </li>
          </ul>
        </div>
        <div class="success__actions" v-if="!getConfig.skip_datasources_page">
        <button
          class="button--primary"
          @click="onBackTo()"
          :style="`background: ${getConfig.cta_button_backcolor};
                   color: ${getConfig.cta_button_forecolor}`">
          <span v-if="getConfig.success_button_text">{{getConfig.success_button_text}}</span>
          <Translate v-else :translate="'goBack'" />
        </button>
      </div>
    </div>

    <div class="success__wrapper" v-else>
      <div
        class="success__section"
        :class="{
          'success__section--merged': getSelectedSource?.merged_logo,
        }">
        <DataSourceAvatar :source="getSelectedSource" :withShadow="true" />
        <h2 class="success__section-title">
          <Translate :translate="'completed'" />
        </h2>
        <p class="success__section-text" v-if="getConfig.success_show_description">
          <span v-if="getConfig.success_description">{{getConfig.success_description}}</span>
          <Translate v-else :translate="'accountAddedSuccessfully'" />
        </p>
      </div>
      <div class="success__actions" v-if="!getConfig.skip_datasources_page">
        <button
          class="button--primary"
          @click="onBackTo()"
          :style="`background: ${getConfig.cta_button_backcolor};
                   color: ${getConfig.cta_button_forecolor}`">
          <span v-if="getConfig.success_button_text">{{getConfig.success_button_text}}</span>
          <Translate v-else :translate="'goBack'" />
        </button>
        <button
          v-if="filteredSource.length > 0"
          class="button--secondary"
          @click="onAddAnotherAccount()">
          <Translate :translate="'addAccount'" />
        </button>
      </div>
    </div>
    <ConnectedSources v-if="!getConfig.skip_datasources_page && getConnectedSources?.length > 0" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header';
import DataSourceAvatar from '@/components/DataSourceAvatar';
import ConnectedSources from '@/components/ConnectedSources';
import CarrotRightIcon from '@/components/icons/CarrotRightIcon';
import { isEmpty } from 'lodash';

export default {
  name: 'Success',
  components: {
    ConnectedSources,
    DataSourceAvatar,
    CarrotRightIcon,
    Header,
  },
  data() {
    return {
      redirectTimeout: undefined,
    };
  },
  mounted() {
    const message = `{"onCompleted": true, "userUUID": "${this.$route.params.id}"}`;
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(message);
    } else {
      window.top.postMessage(message, '*');
      // eslint-disable-next-line
      window.RolleeConnectFlutter && window.RolleeConnectFlutter.postMessage(message);
    }
    if (this.$store.state.config.redirect && this.getConfig.datasource) {
      this.redirectTimeout = setTimeout(() => {
        window.location.href = this.$store.state.config.redirect;
      }, 3000);
    }
    // When skip data source page is true hide the actions and navigate back to terms screen
    if (this.getConfig.skip_datasources_page) {
      setTimeout(() => {
        this.onBackTo();
      }, 3000);
    }
  },
  unmounted() {
    if (!this.getConfig.skip_datasources_page && !this.getConfig.success_available_datasources) {
      this.$store.state.selectedSource = undefined;
    }
    clearInterval(this.redirectTimeout);
    this.$store.state.connectedSources = [];
    this.$store.commit('SET_FETCH_UUID', null);
    this.$store.state.selectedSource = undefined;
  },
  computed: {
    ...mapGetters(['getConfig', 'getAvailableSources','getSelectedSource', 'getConnectedSources', 'getSettings']),
    customerName() {
      return this.getConfig.partner_title;
    },
    filteredSource() {
      if (this.getAvailableSources.sources !== null && !isEmpty(this.getAvailableSources)) {
        // eslint-disable-next-line
        const reducedSources = this.getAvailableSources.sources.filter((source) => {
          // eslint-disable-next-line
          if(source.multiple){
            return true;
          }
          if(source.ID === this.getSelectedSource?.ID){
            return false;
          }
          // filter reducedSources by last-fetch if complete filter out
          if ((source['last-fetch'] && source['last-fetch'].status === 'complete') && !source.multiple) {
            return false;
          }

          if (!this.getConnectedSources?.some(
            (connectedSource) => connectedSource.name === source.name,
          )) {
            return true;
          }
          return false;
        });
        return reducedSources;
      }
      return [];
    },
  },
  methods: {
    onBackTo() {
      // eslint-disable-next-line
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('closeSession');
      } else {
        window.top.postMessage('closeSession', '*');
        // eslint-disable-next-line
        window.RolleeConnectFlutter && window.RolleeConnectFlutter.postMessage('closeSession');
      }

      if (this.$store.state.config.redirect) {
        window.location.href = this.$store.state.config.redirect;
        return;
      }
      this.$router.push({ name: 'Search', query: this.$route.query });
      window?.analytics.track('back_success');
    },
    onAddAnotherAccount() {
      setTimeout(() => {
        this.$store.state.selectedSource = undefined;
      }, 1000);
      this.$router.push({ name: 'Search', query: this.$route.query, params: { searchFocus: true } });
      window?.analytics.track('add_another_success');
    },
    connect(source) {
      this.$store.commit('SET_SELECTED_SOURCE', source);
      // Check if data source contains 'ongoing_fetch' id
      // If true skip login screen and start fetch polling with UUID
      if (source['ongoing-fetch']) {
        this.$store.commit('SET_REFRESHED_UUID', source['ongoing-fetch']);
        this.$store.commit('SET_AUTH', true);
        this.$router.push({
          name: 'Fetch',
          query: this.$route.query
        });
        return;
      }
      // Check if source is acme-rev and redirect to files
      // If not redirect to login
      switch (true) {
        case source.name === "MPESA":
          this.$router.push({
            name: 'Files',
            query: this.$route.query
          });
          break;
        default:
          this.$router.push({
            name: 'Login',
            query: this.$route.query
          });
          break;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.success{
  &__wrapper{
    display: grid;
    grid-template-rows: auto 116px;
    row-gap: 20px;
    width: 100%;

    &-platforms{
      display: grid;
      grid-template-rows: auto minmax(220px, 330px) 116px;
      width: 100%;
      margin-top: 20px;

      .page__list{
        margin-top: 10px;
      }
      .success{
        &__actions{
          margin-top: 20px;
        }
      }
    }
  }
  &__section {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title{
      color: $black;
      font-size: 17px;
      line-height: 24px;
      font-weight: 600;
      margin: 20px 0 6px 0;
    }

    &-text{
      color: $dark-grey;
      font-size: 15px;
      line-height: 24px;
    }
    &--merged {
      :deep(.source__wrapper) {
        img {
          width: 90px;
          height: 60px;
          object-fit: contain;
          border: none;
        }
      }
    }
  }
  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
