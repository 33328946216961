<template>
  <div class="error" :class="{desktop: !isMobile}">
    <div class="error__content">
      <ExclamationIcon class="error__icon" />
      <span class="error__desc">{{ errorDescription }}</span>
    </div>
    <div class="error__close-wrapper">
      <CloseIcon
        class="error__close"
        @click="closeError()"/>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ExclamationIcon from './icons/ExclamationIcon';
import CloseIcon from './icons/CloseIcon';
import General from '@/mixins/general';

export default {
  name: 'ErrorNotification',
  mixins: [General],
  computed: {
    ...mapGetters(['getError', 'getFetchStatus', 'translations']),
    errorDescription() {
      // Check if description provided in error
      if (this.getError.description) {
        return this.getError.description;
      }
      // Display default error message when non are provided
      return this.translations.errors.generic;
    },
  },
  methods: {
    closeError() {
      this.$store.commit('SET_ERROR', null);
    },
  },
  components: {
    ExclamationIcon,
    CloseIcon,
  },
};
</script>
<style lang="scss" scoped>
.error {
  position: absolute;
  bottom: 20px;
  left: 0;
  margin: 0 20px;
  display: grid;
  grid-template-columns: calc(100% - 18px - 8px) 18px;
  column-gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #FEEEED;
  width: calc(100% - 40px);
  min-height: 40px;
  border-radius: 8px;
  padding: 10px 12px;
  box-sizing: border-box;
  border: 1px solid #F52922;
  box-shadow: 0px 4px 32px 0px rgba(20, 20, 20, 0.08);
  z-index: 9;
  &__content {
    display: grid;
    grid-template-columns: 18px auto;
    column-gap: 8px;
    align-items: center;
  }
  &__desc {
    color: #F52922;
    transform: translateY(1px);
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    text-align: start;
    word-break: break-word;
  }
  &__icon {
    justify-self: center;
  }
  &__close {
    justify-self: end;
    cursor: pointer;
    color: #F52922;
    height: 14px;
    width: 14px;
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
    }
  }
  &.desktop {
    margin: 0 80px;
    width: calc(100% - 160px);
    bottom: 40px;
  }
}
</style>
